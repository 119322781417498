/*----------------------------------------
Header.css
playrps.ru |
Created by Andrey Shpigunov at 27.01.2025
All right reserved.
----------------------------------------*/


.headerLine {
  
}
.headerLine-logo {
  width: 60px;
  
  & img {
    width: 60px;
  }
}
.headerLine-title {
  font-family: var(--headers-font-family);
}
.headerLine-closer {
  width: 60px;
}
