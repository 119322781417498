/*---------------------------
Block.module.css
playrps.ru

Created by Andrey Shpigunov on 05.11.2024.
---------------------------*/


.block {
  background: #fff;
  border: 2px solid var(--font-color);
}
