/*---------------------------
Buttons.css
playrps.ru

Created by Andrey Shpigunov on 05.11.2024.
---------------------------*/


:root {
  /* Base styles */
  --button-side-padding: 20px;
  --button-transition: none;
  --button-disabled-opacity: .5;
  
  /* Transformations */
  --button-transform: translate(0);
  --button-transform-hover: translate(0);
  --button-transform-active: translate(2px, 2px);
  
  /* Colors */
  --button-color: #fcf6f0;
  --button-color-hover: var(--button-color);
  --button-color-active: #fbeee0;
  --button-font-color: var(--font-color);
  --button-font-color-hover: var(--button-font-color);
  --button-font-color-active: var(--button-font-color);
}


.button {
  --button-height: 50px;
  --button-font-size: 18px;
  --button-font-weight: 700;
  --button-border-radius: 30px;
  
  border: 2px solid var(--font-color);
  box-shadow: var(--font-color) 3px 3px 0 0;
  touch-action: manipulation;
}
.button_s {
  --button-height: 40px;
  --button-font-size: 16px;
  --button-font-weight: 700;
  --button-border-radius: 30px;
}
.button_icon {
  --button-height: 80px;
  --button-side-padding: 0;
  --button-font-size: 16px;
  --button-font-weight: 700;
  --button-border-radius: 40px;
  
  width: 80px;
  
  & > img {
    pointer-events: none;
    width: 60px;
    height: 60px;
    margin: 0 auto;
  }
}

.button:hover {
  color: var(--font-color);
  /* background-color: #fbeee0; */
}

.button:active {
  color: var(--font-color);
  box-shadow: var(--font-color) 2px 2px 0 0;
  transform: translate(1px, 1px);
}

