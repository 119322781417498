@import url('https://fonts.googleapis.com/css2?family=Galindo&display=swap');

:root {
  --line-height: 1.5;
  --font-size: 18px;
  --font-color: #442800;
  --headers-font-color: #442800;
  --headers-font-family: Galindo, var(--font-family);
  
  --background-color: #fdf6dd;
  
  --h1-font-size: 2em;
  --h2-font-size: 1.75em;
  --h3-font-size: 1.5em;
  --h4-font-size: 1.25em;
  --h5-font-size: 1em;
  --h6-font-size: 1em;
  
  /* --forms-width: 400px; */
  --forms-height: 40px;
  --forms-side-padding: 16px;
  
  /* --forms-font-size: var(--font-size); */
  --forms-font-color: var(--font-color);
  /* --forms-font-color-readonly: #000; */
  /* --forms-font-color-disabled: #666; */
  /* --forms-font-color-placeholder: #ccc; */
  
  --forms-border-width: 2px;
  --forms-border-radius: 40px;
  --forms-border-color: var(--font-color);
  --forms-border-color-focused: none;
  /* --forms-border-color-error: #f00; */
  /* --forms-border-color-readonly: #ddd; */
  /* --forms-border-color-disabled: #ddd; */
  
  /* --forms-background-color: #fff; */
  /* --forms-background-color-readonly: #fafafa; */
  /* --forms-background-color-disabled: #f6f6f6; */
}

html,
body {
  min-height: 100vh;
  overflow: hidden;
  overscroll-behavior: none;
}

#root {
  height: 100vh;
  background: var(--background-color);
  /* Preserve scrolling behavior */
  overflow-y: scroll;
}
