/*----------------------------------------
NotTelegram.css
playrps.ru
Created by Andrey Shpigunov at 26.01.2025
All right reserved.
----------------------------------------*/


.notTelegram {
  --link-color: var(--font-color);
  --link-color-hover: var(--link-color);
  --link-color-active: var(--link-color);
  --link-color-visited: var(--link-color);
  --link-border-color: #ffffff00;
  --link-border-color-hover: var(--link-color);
  --link-underline-thickness: 2px;
  --link-transition: all .2s ease-out;
  
  position: fixed;
  z-index: 999999;
  inset: 0;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  background: var(--background-color);
  
  & svg {
    fill: var(--font-color)
  }
}
