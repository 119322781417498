.app {
  /* display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh; */
  
  height: 100vh;
  /* border-left: solid 1px var(--font-color); */
  /* border-right: solid 1px var(--font-color); */
}

.loader {
  position: fixed;
  z-index: 99999999;
  inset: 0;
  display: flex;
  padding-top: 40vh;
  justify-content: center;
  font-weight: 700;
  background: #fdf6ddee;
  will-change: opacity;
  opacity: 0;
  transition: opacity .2s ease-out;
  pointer-events: none;
  
  &.active {
    opacity: 1;
    pointer-events: auto;
  }
}
